import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import BFMessageBox from "@/modules/abstract-ui/general/Message/BFMessageBox";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import React from "react";
import PersistentSplitPane from "../../../../../../../configurable/data/SplitPane/PersistentSplitPane";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import GlobalActions from "../../../../../../../services/GlobalActions";
import { EnrichtedRentalUnit, RentalAgreement } from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import CBAgreementAutomatic from "./agreement-parts/CBAgreementAutomatic";
import CBAgreementContractBasedata from "./agreement-parts/CBAgreementContractBasedata";
import CBAgreementCopayment from "./agreement-parts/CBAgreementCopayment";
import CBAgreementDeposit from "./agreement-parts/CBAgreementDeposit";
import CBAgreementGraduatedRent from "./agreement-parts/CBAgreementGraduatedRent";
import CBAgreementIndex from "./agreement-parts/CBAgreementIndex";
import CBAgreementOption from "./agreement-parts/CBAgreementOption";
import CBAgreementPaymentInfo from "./agreement-parts/CBAgreementPaymentInfo";
import CBAgreementRentalUnits from "./agreement-parts/CBAgreementRentalUnits";
import CBAgreementRentDuration from "./agreement-parts/CBAgreementRentDuration";
import "./CBRentalAgreementDetailsPage.scss";

interface CBRentalAgreementDetailsProps {
  goBack?: () => void;
  rentalAgreement: RentalAgreement;
  hideBackButton?: boolean;
  hideStackingPlan?: boolean;
  hideActions?: boolean;
  kind: ObjectKind;
}
export const CBRentalAgreementDetailsPage = (
  props: CBRentalAgreementDetailsProps
) => {
  const chartId = React.useRef<string>(null);
  const comp = (
    <>
      <div className={`page-content`}>
        <div className={`header`}>
          {!props.hideBackButton && (
            <BFButton
              className={`back-button`}
              appearance={"clear-on-white"}
              icon={{ size: "xs", ...DefaultIcons.BACK }}
              onClick={props.goBack}
            />
          )}
          <div className={`title`}>
            {OrgaStruct.isImportedObject(
              props.rentalAgreement.data.objectId
            ) && (
              <div className={`margin-top-10`}>
                <BFMessageBox type="info" inline small>
                  {i18n.t(
                    "cb:RentalAgreement.ImportedObject",
                    "Importierter Mietvertrag aus Domus"
                  )}
                </BFMessageBox>
              </div>
            )}
            <div className={`id`}>
              {i18n.t("cb:RentalAgreement.DetailsTitle", "Mietvertrag")}{" "}
              {props.rentalAgreement.data.id}
              <DebugDataComponent data={props.rentalAgreement} />
            </div>
            <div className={`name`}>
              {props.rentalAgreement.data.displayName}
            </div>
            <div className={`object`}>
              <BFDetailsButton
                appearance="link"
                size="xs"
                data={{
                  assetType: AssetTypes.Portfolio.Object,
                  assetId: props.rentalAgreement.data.objectId,
                  type: props.rentalAgreement.data.type,
                }}
              >
                {OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.id}{" "}
                -{" "}
                {
                  OrgaStruct.getObject(props.rentalAgreement.data.objectId)
                    ?.displayName
                }
              </BFDetailsButton>
            </div>
          </div>
        </div>
        <div className={`content`}>
          <CBAgreementRentDuration
            marks={props.rentalAgreement.data.marks?.rentDuration}
            rentalAgreement={props.rentalAgreement}
            // editable //todo make editable
            data={{
              moveIn: props.rentalAgreement.data.moveIn,
              agreementExpiration:
                props.rentalAgreement.data.agreementExpiration,
              moveOut: props.rentalAgreement.data.moveOut,
            }}
          />
          <CBAgreementContractBasedata
            rentalAgreement={props.rentalAgreement}
            // editable //todo make editable
            data={{
              contractType: props.rentalAgreement.data.contractType,
              displayName: props.rentalAgreement.data.displayName,
              id: props.rentalAgreement.data.id,
              note: props.rentalAgreement.data.note,
            }}
          />
          <CBAgreementPaymentInfo
            data={{
              paymentPositions: props.rentalAgreement.data.paymentPositions,
              rentPaymentTimingOptions:
                props.rentalAgreement.data.rentPaymentTimingOptions,
              taxable: props.rentalAgreement.data.taxable
                ? "taxable"
                : "notTaxable",
            }}
            kind={props.kind}
            marks={props.rentalAgreement.data.marks?.paymentInfo}
            objectId={props.rentalAgreement.data.objectId}
            editable
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementDeposit
            editable
            data={{
              deposit: props.rentalAgreement.data.deposit,
            }}
            marks={props.rentalAgreement.data.marks?.graduatedRent}
            objectId={props.rentalAgreement.data.objectId}
            rentalAgreement={props.rentalAgreement}
          />

          <CBAgreementGraduatedRent
            kind={props.kind}
            marks={props.rentalAgreement.data.marks?.graduatedRent}
            objectId={props.rentalAgreement.data.objectId}
            taxable={props.rentalAgreement.data.taxable}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementRentalUnits
            editable
            marks={props.rentalAgreement.data.marks?.rentalUnits}
            data={{
              rentalUnits: props.rentalAgreement.data.rentalUnits,
            }}
            rentalAgreement={props.rentalAgreement}
            kind={props.kind}
            objectId={props.rentalAgreement.data.objectId}
            hideStackingplan
          />
          <CBAgreementIndex
            editable
            marks={props.rentalAgreement.data.marks?.index}
            data={{
              index: props.rentalAgreement.data.index,
            }}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementOption
            editable
            marks={props.rentalAgreement.data.marks?.option}
            data={{
              option: props.rentalAgreement.data.option,
            }}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementAutomatic
            editable
            data={{
              automatic: props.rentalAgreement.data.automatic,
            }}
            marks={props.rentalAgreement.data.marks?.automatic}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementCopayment
            editable
            data={{
              copayment: props.rentalAgreement.data.copayment,
            }}
            marks={props.rentalAgreement.data.marks?.copayment}
            rentalAgreement={props.rentalAgreement}
          />
        </div>
      </div>
    </>
  );
  return (
    <div className="cb-rental-agreement-details">
      {props.hideStackingPlan ? (
        comp
      ) : (
        <PersistentSplitPane
          identifier="cb-rental-agreement-details"
          split="horizontal"
          defaultSize={"50%"}
          maxSize={-200}
          minSize={150}
          primary="first"
          allowResize
          onSizeChange={() => {
            if (chartId.current) {
              DataBusDefaults.chartResized(chartId.current);
            }
          }}
        >
          {props.hideStackingPlan === true ? null : (
            <div className={`object-stacking-plan-wrapper`}>
              <ObjectStackingPlan
                onMounted={(ident, chart) => {
                  chartId.current = ident;
                }}
                objectId={props.rentalAgreement.data.objectId}
                markedUnits={props.rentalAgreement.data.rentalUnits}
                selectMode="single"
                onSelect={(selected: EnrichtedRentalUnit) => {
                  GlobalActions.openDetails(
                    selected.data.rentalStatus === "vacant"
                      ? AssetTypes.Rental.RentalUnit
                      : AssetTypes.Rental.RentalAgreement,
                    selected.data.rentalStatus === "vacant"
                      ? selected._id
                      : selected.data.agreement._id,
                    selected.data.type
                  );
                  //   DataBusDefaults.route({
                  //     route: `/rental-unit/${selected}`,
                  //     stayInApp: true,
                  //   });
                }}
              />
            </div>
          )}
          {comp}
        </PersistentSplitPane>
      )}
    </div>
  );
};

const RentalAgreementSection = (props: {
  title: string;
  onEdit?: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className={`rental-agreement-section`}>
      <div className={`section-title`}>
        <div className={`__h3`}>{props.title}</div>
        {props.onEdit && (
          <BFButton
            noPadding
            appearance="link"
            onClick={props.onEdit}
            size="sm"
          >
            {i18n.t("Global.Buttons.Customize", "Anpassen")}
          </BFButton>
        )}
      </div>
      <div className={`section-content`}>{props.children}</div>
    </div>
  );
};
