import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import MultiplePDFViewer from "@/components/PDFViewer/MultiplePDFViewer";
import i18n from "@/i18n";
import FileUtils from "@/utils/FileUtils";
import classNames from "classnames";
import { useState } from "react";
import CBAgreementAutomatic from "../agreement-parts/CBAgreementAutomatic";
import CBAgreementContractBasedata from "../agreement-parts/CBAgreementContractBasedata";
import CBAgreementContractor from "../agreement-parts/CBAgreementContractor";
import CBAgreementCopayment from "../agreement-parts/CBAgreementCopayment";
import CBAgreementDeposit from "../agreement-parts/CBAgreementDeposit";
import CBAgreementGraduatedRent from "../agreement-parts/CBAgreementGraduatedRent";
import CBAgreementIndex from "../agreement-parts/CBAgreementIndex";
import CBAgreementOption from "../agreement-parts/CBAgreementOption";
import CBAgreementPaymentInfo from "../agreement-parts/CBAgreementPaymentInfo";
import CBAgreementRentalUnits from "../agreement-parts/CBAgreementRentalUnits";
import CBAgreementRentDuration from "../agreement-parts/CBAgreementRentDuration";
import CBAgreementTenant from "../agreement-parts/CBAgreementTenant";
import { CBRentalAgreementFormWizardStep1Value } from "./CBRentalAgreementFormWizardStep1UploadContract";
import { CBRentalAgreementFormWizardStep2Value } from "./CBRentalAgreementFormWizardStep2ContractPartners";
import { CBRentalAgreementFormWizardStep3Value } from "./CBRentalAgreementFormWizardStep3RentalUnits";
import { CBRentalAgreementFormWizardStep4Value } from "./CBRentalAgreementFormWizardStep4ContractData";
import "./CBRentalAgreementFormWizardStep5Overview.scss";

export type CBRentalAgreementFormWizardStep5Value = {};
interface CBRentalAgreementFormWizardStep5OverviewProps {
  kind: ObjectKind;
  data: {
    step1: CBRentalAgreementFormWizardStep1Value;
    step2: CBRentalAgreementFormWizardStep2Value;
    step3: CBRentalAgreementFormWizardStep3Value;
    step4: CBRentalAgreementFormWizardStep4Value;
  };
  initialValues?: CBRentalAgreementFormWizardStep5Value;
  onBack: () => void;
  onSuccess: (values: CBRentalAgreementFormWizardStep5Value) => Promise<void>;
}
const CBRentalAgreementFormWizardStep5Overview = (
  props: CBRentalAgreementFormWizardStep5OverviewProps
) => {
  const [fileUrl, setFileUrl] = useState(
    FileUtils.fileToObjectUrl(props.data?.step1?.rentalAgreement?.[0]?.file)
  );
  const objectId = props.data.step2.objectId;

  return (
    <FormStruct
      title={i18n.t("cb:RentalAgreement.Form.Step5.title", "Daten überprüfen")}
      description={i18n.t(
        "cb:RentalAgreement.Form.Step5.description",
        "Prüfen Sie alle Vertragsbestandteile"
      )}
      onSubmit={async (values) => {
        await props.onSuccess(values);
      }}
      submitText={i18n.t(
        "cb:RentalAgreement.Form.Step5.submit",
        "Mietvertrag erstellen"
      )}
      onAbort={props.onBack}
      initialValues={props.initialValues}
      cancelText={i18n.t("Global.Buttons.back")}
      className={classNames(`cb-rental-agreement-form-wizard-step5-overview`)}
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <MultiplePDFViewer
            identifier="rental-agreement-pdf"
            height={"78vh"}
            label={{
              single: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreement",
                "Mietvertrag"
              ),
              multiple: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreements",
                "Mietverträge"
              ),
            }}
            pdfs={props.data.step1?.rentalAgreement.map((e) => ({
              type: "file",
              file: e.file,
            }))}
          />
        </div>
      )}
      render={(form) => (
        <div>
          <CBAgreementContractor
            marks={props.data.step2.marks?.entity}
            data={props.data.step2}
          />

          <CBAgreementTenant
            marks={props.data.step2.marks?.tenant}
            data={props.data.step2}
            kind={props.kind}
          />

          <CBAgreementRentalUnits
            objectId={props.data.step2.objectId}
            marks={props.data.step3.marks?.rentalUnits}
            data={{
              rentalUnits: props.data.step3.rentalUnits.map((e) => e.assetId),
            }}
            kind={props.kind}
          />
          <CBAgreementContractBasedata data={props.data.step4} />
          <CBAgreementRentDuration
            data={props.data.step4}
            marks={props.data.step4.marks?.rentDuration}
          />

          <CBAgreementPaymentInfo
            marks={props.data.step4.marks?.paymentInfo}
            data={props.data.step4}
            objectId={props.data.step2.objectId}
            kind={props.kind}
          />
          <CBAgreementDeposit
            hideIfEmpty
            marks={props.data.step4.marks?.deposit}
            data={props.data.step4}
            objectId={props.data.step2.objectId}
          />
          <CBAgreementOption
            hideIfEmpty
            marks={props.data.step4.marks?.option}
            data={props.data.step4}
          />

          <CBAgreementAutomatic
            hideIfEmpty
            marks={props.data.step4.marks?.automatic}
            data={props.data.step4}
          />

          <CBAgreementCopayment
            hideIfEmpty
            marks={props.data.step4.marks?.copayment}
            data={props.data.step4}
          />

          <CBAgreementIndex
            marks={props.data.step4.marks?.index}
            data={props.data.step4}
            hideIfEmpty
          />
          <CBAgreementGraduatedRent
            marks={props.data.step4.marks?.graduatedRent}
            data={props.data.step4}
            hideIfEmpty
            kind={props.kind}
            objectId={props.data.step2.objectId}
            taxable={props.data.step4.taxable === "taxable"}
          />
        </div>
      )}
    />
  );
};

export default CBRentalAgreementFormWizardStep5Overview;
