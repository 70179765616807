import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import LoadMask from "@/components/LoadMask/LoadMask";
import ModalManager from "@/components/ModalComponent/ModalManager";
import PersistentSplitPane from "@/configurable/data/SplitPane/PersistentSplitPane";
import i18n from "@/i18n";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { useHttpCache } from "@/redux/hooks";
import DataBusDefaults from "@/services/DataBusDefaults";
import LanguageService from "@/services/LanguageService";
import { hasValue } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Field, FormRenderProps } from "react-final-form";
import { FieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import CBRentalService from "../../CBRentalService";
import {
  EnrichtedRentalUnit,
  RentalUnitFormValue,
} from "../../TenantsInterfaces";
import ObjectStackingPlan, {
  ObjectStackingPlanUnitWithBuilding,
} from "../stacking-plan/ObjectStackingPlan";
import "./CBRentalUnitMultipleForm.scss";

interface CBRentalUnitMultipleFormProps {
  onClose: () => void;
  onSuccess?: () => void;
  objectId: string;
  kind: ObjectKind;
}

export type CBRentalUnitMultipleFormValue = {
  rentalUnits: RentalUnitFormValue[];
};

const onStackingPlanFocus = (index: number, stackingPlanInstance: any) => {
  const isSelected =
    stackingPlanInstance.getOption().geo?.[0]?.selectedMap?.[index];
  if (!isSelected) {
    stackingPlanInstance?.dispatchAction({
      type: "geoSelect",
      name: `${index}`,
    });
  }
};
const getColumns = (kind: ObjectKind) => [
  {
    name: (group: "areal" | "parking" | "other") =>
      i18n.t("cb:CBRentalUnitMultipleForm.id", "ID"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <Field name={`${name}.id`} validate={FV.compose(FV.required())}>
        {({ input, meta }) => (
          <BFInput
            className={`cb-rental-unit-id-input-${index}`}
            placeholder={i18n.t("cb:CBRentalUnitMultipleForm.id", "ID")}
            {...input}
            onFocus={() => {
              input.onFocus();
              onStackingPlanFocus(index, stackinPlanInstance);
            }}
            {...FV.getValidation(meta)}
          />
        )}
      </Field>
    ),
  },
  {
    name: (group: "areal" | "parking" | "other") =>
      i18n.t("cb:CBRentalUnitMultipleForm.displayName", "Name"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <Field name={`${name}.displayName`} validate={FV.compose(FV.required())}>
        {({ input, meta }) => (
          <BFInput
            useTempValue
            className={`cb-rental-unit-name-input-${index}`}
            placeholder={i18n.t(
              "cb:CBRentalUnitMultipleForm.displayName",
              "Name"
            )}
            {...input}
            onFocus={() => {
              input.onFocus();
              onStackingPlanFocus(index, stackinPlanInstance);
            }}
            {...FV.getValidation(meta)}
          />
        )}
      </Field>
    ),
  },
  {
    name: (group: "areal" | "parking" | "other") =>
      i18n.t("cb:CBRentalUnitMultipleForm.unitType", "Art"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <FormFieldValues names={[`${name}.unitGroup`]}>
        {([unitGroup]) => (
          <Field name={`${name}.unitType`} validate={FV.compose(FV.required())}>
            {({ input, meta }) => (
              <BFSelect
                placeholder={i18n.t(
                  "cb:CBRentalUnitMultipleForm.unitType",
                  "Art"
                )}
                groupBy={"group"}
                {...input}
                onFocus={() => {
                  input.onFocus();
                  onStackingPlanFocus(index, stackinPlanInstance);
                }}
                {...FV.getValidation(meta)}
                data={ObjectKindStruct.getUnitTypeSelectOptions(
                  kind._id,
                  ObjectKindStruct.getGroupByStackingPlanUnitType(unitGroup)
                )}
              />
            )}
          </Field>
        )}
      </FormFieldValues>
    ),
  },
  {
    name: (group: "areal" | "parking" | "other") =>
      i18n.t("cb:CBRentalUnitMultipleForm.floor", "Etage"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <Field name={`${name}.floor`} validate={FV.compose(FV.required())}>
        {({ input, meta }) => (
          <BFInput
            useTempValue
            type="number"
            step={1}
            {...input}
            onFocus={() => {
              input.onFocus();
              onStackingPlanFocus(index, stackinPlanInstance);
            }}
            {...FV.getValidation(meta)}
          />
        )}
      </Field>
    ),
  },
  {
    name: (group: "areal" | "parking" | "other") =>
      group === "areal"
        ? i18n.t("cb:CBRentalUnitMultipleForm.area", "Fläche")
        : i18n.t("cb:CBRentalUnitMultipleForm.quantity", "Anzahl"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <FormFieldValues names={[`${name}.unitGroup`]}>
        {([unitGroup]) => {
          if (unitGroup === "areal") {
            return (
              <Field name={`${name}.area`}>
                {({ input, meta }) => (
                  <BFInput
                    useTempValue
                    type="number"
                    prefix={StringUtils.getAreaUnit()}
                    {...input}
                    onFocus={() => {
                      input.onFocus();
                      onStackingPlanFocus(index, stackinPlanInstance);
                    }}
                    {...FV.getValidation(meta)}
                  />
                )}
              </Field>
            );
          } else {
            return (
              <Field name={`${name}.quantity`}>
                {({ input, meta }) => (
                  <BFInput
                    useTempValue
                    type="number"
                    step={1}
                    {...input}
                    onFocus={() => {
                      input.onFocus();
                      onStackingPlanFocus(index, stackinPlanInstance);
                    }}
                    {...FV.getValidation(meta)}
                  />
                )}
              </Field>
            );
          }
        }}
      </FormFieldValues>
    ),
  },

  {
    name: () =>
      i18n.t("cb:CBRentalUnitMultipleForm.rentNet", "Planmiete Netto"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <FormFieldValues allProps names={[`${name}.rentGross`]}>
        {([rentGross]) => (
          <Field name={`${name}.rentNet`} validate={FV.compose(FV.required())}>
            {({ input, meta }) => (
              <BFInput
                useTempValue
                type={"priceInput"}
                suffix={StringUtils.getCurrencySymbol()}
                {...input}
                onFocus={() => {
                  input.onFocus();
                  stackinPlanInstance?.dispatchAction({
                    type: "geoSelect",
                    name: `${index}`,
                  });
                }}
                onChange={(value: number) => {
                  input.onChange(value);

                  rentGross.input.onChange(value * 1.19);
                }}
                {...FV.getValidation(meta)}
              />
            )}
          </Field>
        )}
      </FormFieldValues>
    ),
  },
  {
    name: () =>
      i18n.t("cb:CBRentalUnitMultipleForm.rentGross", "Planmiete Brutto"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <FormFieldValues allProps names={[`${name}.rentNet`]}>
        {([rentNet]) => (
          <Field
            name={`${name}.rentGross`}
            validate={FV.compose(FV.required())}
          >
            {({ input, meta }) => (
              <BFInput
                type={"priceInput"}
                suffix={StringUtils.getCurrencySymbol()}
                {...input}
                onFocus={() => {
                  input.onFocus();
                  stackinPlanInstance?.dispatchAction({
                    type: "geoSelect",
                    name: `${index}`,
                  });
                }}
                onChange={(value: number) => {
                  input.onChange(value);

                  rentNet.input.onChange((value / 119) * 100);
                }}
                {...FV.getValidation(meta)}
              />
            )}
          </Field>
        )}
      </FormFieldValues>
    ),
  },

  {
    name: () =>
      i18n.t("cb:CBRentalUnitMultipleForm.operatingCostNet", "Plan NBK Netto"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <FormFieldValues allProps names={[`${name}.operatingCostGross`]}>
        {([operatingCostGross]) => (
          <Field
            name={`${name}.operatingCostNet`}
            validate={FV.compose(FV.required())}
          >
            {({ input, meta }) => (
              <BFInput
                type={"priceInput"}
                suffix={StringUtils.getCurrencySymbol()}
                {...input}
                onFocus={() => {
                  input.onFocus();
                  stackinPlanInstance?.dispatchAction({
                    type: "geoSelect",
                    name: `${index}`,
                  });
                }}
                onChange={(value: number) => {
                  input.onChange(value);

                  operatingCostGross.input.onChange(value * 1.19);
                }}
                {...FV.getValidation(meta)}
              />
            )}
          </Field>
        )}
      </FormFieldValues>
    ),
  },
  {
    name: () =>
      i18n.t(
        "cb:CBRentalUnitMultipleForm.operatingCostGross",
        "Plan NBK Brutto"
      ),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <FormFieldValues allProps names={[`${name}.operatingCostNet`]}>
        {([operatingCostNet]) => (
          <Field
            name={`${name}.operatingCostGross`}
            validate={FV.compose(FV.required())}
          >
            {({ input, meta }) => (
              <BFInput
                type={"priceInput"}
                suffix={StringUtils.getCurrencySymbol()}
                {...input}
                onFocus={() => {
                  input.onFocus();
                  stackinPlanInstance?.dispatchAction({
                    type: "geoSelect",
                    name: `${index}`,
                  });
                }}
                onChange={(value: number) => {
                  input.onChange(value);

                  operatingCostNet.input.onChange((value / 119) * 100);
                }}
                {...FV.getValidation(meta)}
              />
            )}
          </Field>
        )}
      </FormFieldValues>
    ),
  },

  {
    name: () => i18n.t("cb:CBRentalUnitMultipleForm.administratedFrom", "Von"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <Field
        name={`${name}.administratedFrom`}
        validate={FV.compose(FV.required())}
      >
        {({ input, meta }) => (
          <BFDatefield
            preventNull
            {...input}
            onFocus={() => {
              input.onFocus();
              onStackingPlanFocus(index, stackinPlanInstance);
            }}
            {...FV.getValidation(meta)}
          />
        )}
      </Field>
    ),
  },
  {
    name: () => i18n.t("cb:CBRentalUnitMultipleForm.administratedTo", "Bis"),
    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <FormFieldValues names={[`${name}.administratedFrom`]}>
        {([administratedFrom]) => (
          <Field
            name={`${name}.administratedTo`}
            validate={FV.compose(FV.dateAfter(administratedFrom))}
          >
            {({ input, meta }) => (
              <BFDatefield {...input} {...FV.getValidation(meta)} />
            )}
          </Field>
        )}
      </FormFieldValues>
    ),
  },
  {
    name: () => "",

    field: (
      name: string,
      index: number,
      formProps: FormRenderProps<any>,
      stackinPlanInstance: any,
      arrayProps: FieldArrayRenderProps<any, HTMLElement>
    ) => (
      <div className={`__flex padding-left-10 padding-right-10`}>
        <BFButton
          noPadding
          appearance="link"
          onClick={() => {
            arrayProps.fields.push({
              ...arrayProps.fields.value[index],
            });
          }}
        >
          <BfIcon data={"duplicate"} type="light" size="xs" />
        </BFButton>
        <BFButton
          noPadding
          appearance="link"
          onClick={() => {
            arrayProps.fields.remove(index);
          }}
        >
          <BfIcon {...DefaultIcons.CLOSE} size="xs" />
        </BFButton>
      </div>
    ),
  },
];

const CBRentalUnitMultipleForm: React.FC<CBRentalUnitMultipleFormProps> = (
  props
) => {
  const instanceRef = React.useRef<any>(null);
  const chartId = React.useRef<string>(null);
  const data = useHttpCache<EnrichtedRentalUnit[]>(
    `object-stacking-plan-${props.objectId}`,
    `/rental/getStackingPlan`,
    "post",
    null,
    {
      objectIds: [props.objectId],
    }
  );

  if (data.state === "loading") {
    return (
      <div>
        <LoadMask />
      </div>
    );
  }
  if (data.state === "error") {
    return <div>error</div>;
  }
  return (
    <FormStruct
      className={classNames("cb-rental-unit-multiple-form")}
      onSubmit={async (values: CBRentalUnitMultipleFormValue) => {
        const result = await CBRentalService.submitRentalUnits(
          props.objectId,
          values.rentalUnits
        );
        // const asset = (await SubmitService.submitDataAsync({
        //   type: "asset",
        //   assetType: null, // AssetTypes.
        //   data: {
        //     _id: props.asset?._id,
        //     data: {
        //       ...transformFormdataToSubmitdata(values),
        //     },
        //   },
        // })) as BaseAsset;

        data.reload();
        props.onSuccess?.();
        props.onClose();
      }}
      subscription={{
        submitting: true,
      }}
      ignoreSubmitOnEnter
      usePrompt
      //			 description={props.asset ? i18n.t("CBBookingCategoryRuleView.UpdateDescription", "Ändern Sie die Daten des Assets und speichern Sie.") : i18n.t("CBBookingCategoryRuleView.CreateDescription", "Erstellen Sie ein neues Asset und speichern Sie.")}
      submitText={i18n.t(
        "cb:RentalUnitMultipleForm.Submit",
        "Mieteinheiten hinzufügen"
      )}
      onAbort={props.onClose}
      render={(form) => (
        <FieldArray name="addedBuildings">
          {({ fields: addedBuildings }) => (
            <FieldArray
              name="rentalUnits"
              //   validate={FormValidators.compose(FormValidators.required())}
            >
              {(arrayProps) => {
                const allBuidings = _.uniq([
                  null,
                  ...(data.data || [])
                    .map((e) =>
                      hasValue(e.data.building) ? e.data.building : null
                    )
                    .filter((e) => e),
                  ...(addedBuildings.value || []),
                ]);

                const columns = getColumns(props.kind);
                return (
                  <PersistentSplitPane
                    identifier="object-stackingplan"
                    split="horizontal"
                    defaultSize={"50%"}
                    maxSize={-200}
                    minSize={150}
                    primary="first"
                    allowResize
                    onSizeChange={() => {
                      if (chartId.current) {
                        DataBusDefaults.chartResized(chartId.current);
                      }
                    }}
                  >
                    <div className={`plan`}>
                      <ObjectStackingPlan
                        instanceRef={instanceRef}
                        objectId={props.objectId}
                        selectMode="single"
                        onMounted={(ident, chart) => {
                          chartId.current = ident;
                        }}
                        initialOpen
                        onSelect={(
                          selected:
                            | EnrichtedRentalUnit
                            | ObjectStackingPlanUnitWithBuilding
                        ) => {
                          if (selected?._id) {
                            const el = document.querySelector(
                              `.cb-rental-unit-name-input-${selected?._id} input`
                            ) as HTMLInputElement;
                            if (el) {
                              el.focus();
                            } else {
                              instanceRef.current?.dispatchAction({
                                type: "geoUnSelect",
                                name: selected._id,
                              });
                            }
                          }
                        }}
                        additionalUnits={(arrayProps.fields.value || []).map(
                          (value: RentalUnitFormValue, index) => {
                            const unitType = value.unitType
                              ? ObjectKindStruct.getUnitTypeBy(value.unitType)
                              : null;
                            const areal = value.unitGroup === "areal";
                            const topRight = areal
                              ? StringUtils.formatArea(value.area)
                              : value.quantity;
                            return {
                              _id: `${index}`,
                              backgroundColor: "#47cbff",
                              center: value.displayName,
                              quanitity: value.area || value.quantity,
                              floor: value.floor,
                              topLeft: "",
                              centerSub: "",
                              topRight: "" + topRight,
                              bottomLeft: unitType?.displayName
                                ? LanguageService.translateLabel(
                                    unitType.displayName
                                  )
                                : "",
                              bottomRight: "",
                              building: value.building || "",
                              group: value.unitGroup,
                            } as ObjectStackingPlanUnitWithBuilding;
                          }
                        )}
                      />
                    </div>
                    <div className={`table`}>
                      <table>
                        {allBuidings.map((building) => {
                          return (
                            <>
                              <tr>
                                <td
                                  className={`building-header`}
                                  colSpan={columns.length}
                                >
                                  <div>
                                    {building ||
                                      i18n.t(
                                        "cb:RentalUnitMultipleForm.WithoutBuildingAssigned",
                                        "Ohne Gebäudezuordnung"
                                      )}
                                  </div>
                                </td>
                              </tr>
                              {["areal", "parking", "other"].map(
                                (group: "areal" | "parking" | "other") => {
                                  return (
                                    <>
                                      <tr>
                                        <td
                                          className={`building-unittype-header`}
                                          colSpan={columns.length}
                                        >
                                          <div>
                                            {i18n.t(
                                              "ObjectStackingPlan.UnitType." +
                                                group
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      {arrayProps.fields
                                        .map((field, index) => {
                                          const values =
                                            arrayProps.fields.value[index];
                                          const entryBuilding = hasValue(
                                            values.building
                                          )
                                            ? values.building
                                            : null;
                                          const unitGroup = values.unitGroup;
                                          if (
                                            entryBuilding === building &&
                                            unitGroup === group
                                          ) {
                                            return field;
                                          } else {
                                            return null;
                                          }
                                        })
                                        .filter((e) => e).length > 0 && (
                                        <tr>
                                          {columns.map((col) => (
                                            <th className={`header`}>
                                              <div>{col.name(group)}</div>
                                            </th>
                                          ))}
                                        </tr>
                                      )}
                                      {arrayProps.fields.map((field, index) => {
                                        const values =
                                          arrayProps.fields.value[index];
                                        const entryBuilding = hasValue(
                                          values.building
                                        )
                                          ? values.building
                                          : null;
                                        const unitGroup = values.unitGroup;
                                        if (
                                          entryBuilding === building &&
                                          unitGroup === group
                                        ) {
                                          return (
                                            <tr>
                                              {columns.map((col) => (
                                                <td>
                                                  {col.field(
                                                    field,
                                                    index,
                                                    form,
                                                    instanceRef.current,
                                                    arrayProps
                                                  )}
                                                </td>
                                              ))}
                                            </tr>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                      <tr>
                                        <td
                                          className={`add-entry-row`}
                                          colSpan={columns.length}
                                        >
                                          <div>
                                            <BFButton
                                              appearance="link"
                                              size="xs"
                                              onClick={() => {
                                                arrayProps.fields.push({
                                                  displayName: "",
                                                  unitType: null,
                                                  unitGroup: group,
                                                  quantity: 0,
                                                  area: 0,
                                                  building: building,
                                                  floor: 0,
                                                  rentGross: 0,
                                                  rentNet: 0,
                                                  operatingCostGross: 0,
                                                  operatingCostNet: 0,
                                                  administratedFrom: new Date(),
                                                  administratedTo: null,
                                                });
                                              }}
                                            >
                                              {group === "areal" &&
                                                i18n.t(
                                                  "cb:RentalUnitMultipleForm.AddArea",
                                                  "Fläche hinzufügen"
                                                )}
                                              {group === "parking" &&
                                                i18n.t(
                                                  "cb:RentalUnitMultipleForm.AddParking",
                                                  "Stellplatz hinzufügen"
                                                )}
                                              {group === "other" &&
                                                i18n.t(
                                                  "cb:RentalUnitMultipleForm.AddOther",
                                                  "Sonstiges hinzufügen"
                                                )}
                                            </BFButton>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </table>

                      <div className={`add-building-button`}>
                        <BFButton
                          type="button"
                          appearance="outline"
                          onClick={() => {
                            ModalManager.input({
                              title: i18n.t(
                                "cb:RentalUnitMultipleForm.AddBuilding",
                                "Gebäude hinzufügen"
                              ),
                              confirmButtonText: i18n.t(
                                "cb:RentalUnitMultipleForm.AddBuildingSubmitButton",
                                "Gebäude hinzufügen"
                              ),
                              message: i18n.t(
                                "cb:RentalUnitMultipleForm.AddBuildingDescription",
                                "Geben Sie den Namen für das Gebäude an."
                              ),
                              onFinish: (buildingName) => {
                                addedBuildings.push(buildingName);
                              },
                              isEnabled: (value: string) => {
                                return (
                                  !(allBuidings || []).includes(value) &&
                                  value.length > 0
                                );
                              },
                            });
                          }}
                        >
                          {i18n.t(
                            "cb:RentalUnitMultipleForm.AddBuilding",
                            "Gebäude hinzufügen"
                          )}
                        </BFButton>
                      </div>
                    </div>
                  </PersistentSplitPane>
                );
              }}
            </FieldArray>
          )}
        </FieldArray>
      )}
    />
  );
};

export default CBRentalUnitMultipleForm;
